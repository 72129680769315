<template>
    <div>
        
        <PrintReportsSales v-if="!IsPurchase" :dataProps="print" ></PrintReportsSales>
        <PrintReportsPurchase v-else :dataProps="print" ></PrintReportsPurchase>

    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import PrintReportsSales from '@/components/ReportsAndPrintings/PrintReportsSales';
    import PrintReportsPurchase from '@/components/ReportsAndPrintings/PrintReportsPurchase';
    
    export default {
        name: "SubPrint",
        components: {
            PrintReportsSales,
            PrintReportsPurchase
        },
        props: {
            win: {
                type: Object,
                required: true
            },
            data:{
                type: Object,
                required: true
            },
            print:{
                type: Object,
                required: true
            },
            IsPurchase:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                 
                
            }
        },
        computed:{
            
        },
        methods: {
           
        },
        mounted() {
            console.log(this.print); 
            console.log(this.IsPurchase);  
        }

    }
</script>

<style scoped>

</style>