<template>
    <window-portal :open="dataProps.show" >
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión Reporte de Ventas</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1293px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1293px; min-height: 1000px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 957px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style="text-align: center; font-size: 12px;">
                                                        <h3>LIBRO DE REGISTRO DE VENTAS</h3>
                                                         <p><b>{{dataProps.header.reportType  === '1'  ? 'CONTRIBUYENTES' : 'CONSUMIDORES FINALES'  }}</b></p>
                                                    
                                                    <p><b>{{dataProps.header.month + ' ' +dataProps.header.year  }}</b></p>
                                    </div>
                                    <br/>
                                    <!-- Heres is teh body -->
                                    <div style="width: 100%;">
                                        <table style="width: 100% !important; font-size: 6px; border-collapse: collapse; border: 1px solid black;">
                                            <tr style="background: gray; ">
                                                <td :colspan="dataProps.header.reportType  === '1' ? 16 : 22" style="text-align: center; color: white;background: gray;">DATOS DEL DOCUMENTO</td>
                                                
                                            </tr>
                                            
                                            <tr>
                                                <template v-if="dataProps.header.reportType  === '1'">
                                                    <td style="border: 1px solid; padding: 2px; width: 35px;">No.<br/>Item</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">FECHA EMISIÓN DEL DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 500px;">CLASE DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">TIPO DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 500px;">NÚMERO DE RESOLUCIÓN</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE<br/>SERIE DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 50px;">NÚMERO DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">NÚMERO DE<br/>CONTROL INTERNO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">NIT O NRC<br/>DEL CLIENTE</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">NOMBRE, RAZÓN<br/>SOCIAL O DENOMINACIÓN</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">VENTAS EXENTAS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">VENTAS NO SUJETAS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">DÉBITO FISCAL</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">VENTAS A CUENTA<br/>DE TERCEROS NO<br/>DOMICILIADOS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">DÉBITO FISCAL<br/>POR VENTA A CUENTA<br/>DE TERCEROS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 100px;">TOTAL VENTAS</td>
                                                </template>
                                                <template v-else>
                                                    <td style="border: 1px solid; padding: 2px; width: 35px;">No.<br/>Item</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">FECHA DE EMISIÓN</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 400px;">CLASE DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 400px;">TIPO DE DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 400px;">NÚMERO DE RESOLUCIÓN</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 400px;">SERIE DEL DOCUMENTO</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 400px;">NÚMERO DE CONTROL INTERNO (DEL)</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE CONTROL INTERNO (AL)</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE DOCUMENTO (DEL)</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE DOCUMENTO (AL)</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE MÁQUINA REGISTRADORA</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS EXENTAS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS INTERNAS EXENTAS NO SUJETAS A PROPORCIONALIDAD</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS NO SUJETAS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS GRAVADAS LOCALES</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">EXPORTACIONES DENTRO DEL ÁREA CENTROAMERICANA</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">EXPORTACIONES FUERA DEL ÁREA CENTROAMERICANA</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">EXPORTACIONES DE SERVICIOS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS A ZONAS FRANCAS Y DPA (TASA CERO)</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">TOTAL VENTAS</td>
                                                    <td style="border: 1px solid; padding: 2px; width: 150px;">NÚMERO DE ANEXO</td>
                                                </template>     
                                            </tr>
                                            <!--tr v-for="(item, index) in dataProps.data.body" :key="index"-->
                                            <tr v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                                <template v-if="dataProps.header.reportType  === '1'">
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item.index }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["FECHA EMISIÓN DEL DOCUMENTO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["CLASE DE DOCUMENTO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["TIPO DE DOCUMENTO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NÚMERO DE RESOLUCIÓN"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NÚMERO DE RESOLUCIÓN"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NUMERO DE SERIE DE DOCUMENTO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NÚMERO DE DOCUMENTO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NÚMERO DE CONTROL INTERNO"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NIT O NRC DEL CLIENTE"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NOMBRE, RAZÓN SOCIAL O DENOMINACIÓN"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasExentasFormatted"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasNoSujetasFormatted"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["DebitoFiscalFormatted"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasCuentaDeTercerosNoDomiciliadosFormatted"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["DebitoFiscalVentaTercerosFormatted"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["TotalVentasFormatted"] }}</td>
                                                </template>
                                                <template v-else>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ index }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["InvoiceDate"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["ClaseDeDocumento"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["TipoDeDocumento"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NumeroDeResolucion"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["SerieDeDocumento"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NumeroDeControlInternoDel"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NumeroDeControlInternoDelAl"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NumeroDeDocumentoDel"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NumeroDeDocumentoAl"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px;">{{ item["NoDeMaquinaRegistradora"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasExentas"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasInternasExentasNoSujetasAProporcionalidad"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasNoSujetas"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasGravadasLocales"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["ExportacionesDentroDelAreaCentroamericana"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["ExportacionesFueraDelAreaCentroamericana"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["ExportacionesDeServicios"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentasAZonasFrancasYDPATasaCero"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["VentaACuentaDeTercerosNoDomiciliados"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["TotalVentas"] }}</td>
                                                    <td style="border-left: 1px solid; border-right: 1px solid; padding: 2px; text-align: right;">{{ item["NumeroDeAnexo"] }}</td>

                                                </template>
                                            </tr>

                                            

                                        </table>

                                        <template v-if="numPages == (indexPage+1)">
                                            <div style="display: inline-block; width: 100%; vertical-align: top;">
                                                
                                               
                                               
                                                <table  style="width: 100%; margin-top: 1rem; font-size: 12px; border-collapse: collapse; border: 1px solid black;">
                                                    <thead>
                                                        <tr style="background: gray; ">
                                                            <td colspan="6" style="text-align: center; color: white;background: gray;">RESUMEN DE VENTAS</td>
                                                        </tr>
                                                    </thead>
                                                
                                                    <tbody>
                                                        <tr v-for="(value, name, index) in consolidateValues" :key="name">
                                                            

                                                            <td colspan="3" style="text-align: end;" :style="index === borderTp ? 'border-top: 1px solid; text-align: right; padding: 2px; font-weight: bold;': 'text-align: right; padding: 2px;' " >{{ value.label + ':' }}</td>
                                                            <td  colspan="3" :style="index === borderTp ? 'border-top: 1px solid; text-align: right; padding: 2px; font-weight: bold;': 'text-align: right; padding: 2px;' "  >{{ value.value }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            
                                            </div>
                                        </template>

                                    </div>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintReportsSales",
    props: ['dataProps'],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            consolidateValues: {},
            borderTp: 0,
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
        //console.log(this.dataProps);
        //console.log(this.dataProps.data.branch)
        //console.log(this.dataProps.data.dteJson)
        //this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
        //this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
        this.getHeaderFooter()
        // if(this.dataProps.)
        // this.$nextTick(() => {
        //     this.sendMail();
        // })
    },
    computed: {
        /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
        rowsNumberPerReport(){
            return this.dataProps.header.reportType  === '1' ? 20 : 60;
        },
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        },
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                    //console.log('header ', this.dataProps.data.header);
                   
                    // const partes = this.dataProps.data.header.InvoiceDate.split("/");
                    //const dia = partes[0];
                   // const mes = partes[1];
                    //const anio = partes[2];

                    // Crear una nueva fecha en formato "yyyy-mm-dd"
                   // const fechaNueva = `${anio}-${mes}-${dia}`;
                   //const fechaNueva = '2021-01-01'
                   // const url = this.dataProps.data.branch.AuthFeMode == 1 ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}` : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;
                    
                    // Generar el código QR y mostrarlo en el elemento con id "qrcode"

                    /* const opt = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 0.3,
                        margin: 1,
                        color: {
                            dark:"#000000",
                            light:"#ffffff"
                        },
                        width: 100
                    } */

                    // QRCode.toCanvas(this.$refs.qrcode, url, { width: 100 }, (error) => {
                    //     if (error) {
                    //         console.log('Error al generar el código QR:', error);
                    //     }
                    // });
                    
                    /* QRCode.toDataURL(url, opt, (error, url) => {
                        if (error) {
                            console.log('Error al generar el código QR:', error);
                            throw error;
                        }

                        //console.log(url);
                        this.urlQr = url
                        //this.$refs.qrcode.src = url;
                    }); */
                }, 0);

                // this.$nextTick(() => {
                //     this.sendMail();
                // });
                // if(this.dataProps.fromCreateInvoice) {
                // }
            }
        },
        'dataProps'() {
            console.log('dataProps');
            this.calculatePagesOfDoc()
        }
    },
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return numberWithCommas(x);
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        calculateConsolidatedTaxpayer(){
            this.borderTp = 6;
            this.consolidateValues = {
                "VENTAS EXENTAS": {
                    value: 0,
                    label: "VENTAS EXENTAS",
                },
                "VENTAS NO SUJETAS": {
                    value: 0,
                    label: "VENTAS NO SUJETAS",
                },
                "VENTAS GRAVADAS LOCALES": {
                    value: 0,
                    label: "VENTAS GRAVADAS LOCALES",
                },
                "DÉBITO FISCAL": {
                    value: 0,
                    label: "DÉBITO FISCAL",
                },
                "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS": {
                    value: 0,
                    label: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS",
                },
                "DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS": {
                    value: 0,
                    label: "DÉBITO FISCAL POR VENTA A CUENTA DE TERCEROS",
                },
                "TOTAL VENTAS": {
                    value: 0,
                    label: "TOTAL VENTAS",
                },
            }
            const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
           
            // this.dataProps.data.body.forEach(element => {

            //     let exportaciones = parseFloat(element.ExportacionesFormatted.replace(Currency, ''));
            //     let gravada = parseFloat(element.VentasGravadasFormatted.replace(Currency, ''));           
            //     let iva = parseFloat(element.DebitoFiscalFormatted.replace(Currency, ''));
            //     let ventaCuentasTerceros = parseFloat(element.VentaCuentaTercerosFormatted.replace(Currency, ''));
            //     let ivaCuentasTerceros = parseFloat(element.DebitoFiscalCuentaTercerosFormatted.replace(Currency, ''));
            //     let ivaPercepcion = parseFloat(element.ImpuestoPercibidoFormatted.replace(Currency, ''));
            //     let totalPagar = parseFloat(element.VentasTotalesFormatted.replace(Currency, ''));

            //     this.consolidateValues.Exportaciones.value += exportaciones;
            //     this.consolidateValues.InvoiceTotalGravada.value += gravada;
            //     this.consolidateValues.InvoiceTotalIVA.value += iva;
            //     this.consolidateValues.VentaCuentasTerceros.value += ventaCuentasTerceros;
            //     this.consolidateValues.IVACuentasTerceros.value += ivaCuentasTerceros;
            //     this.consolidateValues.InvoiceIvaPerce1.value += ivaPercepcion;
            //     this.consolidateValues.InvoiceTotalPagar.value += totalPagar;

            // });
            //add currency to values and parse 2 decimals
            for (const key in this.consolidateValues) {
                this.consolidateValues[key].value = `${Currency}${numberWithCommas( this.dataProps.data.body.reduce( ( accumulator, { [ key ]: currentValue } ) => accumulator + parseFloat( currentValue ), 0 ) )}`;
            }

            // console.log( 'this.consolidateValues ', this.consolidateValues );
        },
        calculateConsolidatedFinalConsumer(){
            this.borderTp = 9;
            this.consolidateValues = {
                "VentasExentas": {
                    value: 0,
                    label: "VENTAS EXENTAS",
                },
                "VentasInternasExentasNoSujetasAProporcionalidad": {
                    value: 0,
                    label: "VENTAS INTERNAS EXENTAS NO SUJETAS A PROPORCIONALIDAD",
                },
                "VentasNoSujetas": {
                    value: 0,
                    label: "VENTAS NO SUJETAS",
                },
                "VentasGravadasLocales": {
                    value: 0,
                    label: "VENTAS GRAVADAS LOCALES",
                },
                "ExportacionesDentroDelAreaCentroamericana": {
                    value: 0,
                    label: "EXPORTACIONES DENTRO DEL ÁREA CENTROAMERICANA",
                },
                "ExportacionesFueraDelAreaCentroamericana": {
                    value: 0,
                    label: "EXPORTACIONES FUERA DEL ÁREA CENTROAMERICANA",
                },
                "ExportacionesDeServicios": {
                    value: 0,
                    label: "EXPORTACIONES DE SERVICIOS",
                },
                "VentasAZonasFrancasYDPATasaCero": {
                    value: 0,
                    label: "VENTAS A ZONAS FRANCAS Y DPA (TASA CERO)",
                },
                "VentaACuentaDeTercerosNoDomiciliados": {
                    value: 0,
                    label: "VENTAS A CUENTA DE TERCEROS NO DOMICILIADOS",
                },
                "TotalVentas": {
                    value: 0,
                    label: "TOTAL VENTAS",
                }
            }

            const Currency = JSON.parse(localStorage.getItem('branch')).Currency;
            // this.dataProps.data.body.forEach(element => {
            //     let gravada = parseFloat(element.VentasGravadasFormatted.replace(Currency, ''));
            //     let exportaciones = parseFloat(element.ExportacionesFormatted.replace(Currency, ''));
            //     let ventaTotal = parseFloat(element.VentasTotalesFormatted.replace(Currency, ''));

            //     this.consolidateValues.gravadas.value += gravada;
            //     this.consolidateValues.Exportaciones.value += exportaciones;
            //     this.consolidateValues.ventaTotal.value += ventaTotal;
            // });
            for (const key in this.consolidateValues) {
                this.consolidateValues[key].value = `${Currency}${numberWithCommas( this.dataProps.data.body.reduce( ( accumulator, { [ key ]: currentValue } ) => accumulator + parseFloat( currentValue ), 0 ) )}`;
            }
        },
        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try{
                    if(this.dataProps.header.reportType === '1' ){
                        this.calculateConsolidatedTaxpayer()
                        
                    }
                    else{
                        this.calculateConsolidatedFinalConsumer()
                    }

                
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.data.body.length <= this.rowsNumberPerReport ){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < this.rowsNumberPerReport && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= this.rowsNumberPerReport ){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                
                
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('l', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
        doc.save(this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: this.dataProps.data.dteJson,
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf");
                const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  float: right;
}

    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
